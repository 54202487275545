export default [
  { value: -1, text: 'Aucun' },
  { value: 1, text: 'Fait maison' },
  { value: 2, text: 'Plat traditionnel' },
  { value: 3, text: 'Plat végan' },
  { value: 4, text: 'Plat épicé' },
  { value: 5, text: 'Plat du jour' },
  { value: 6, text: 'Hallal' },
  { value: 7, text: 'Accès handicapé' },
  { value: 8, text: 'Parking' },
  { value: 9, text: 'Voiturier' },
  { value: 10, text: 'Espace jeux enfant' },
  { value: 11, text: 'Accueil enfant' },
  { value: 12, text: 'Service continu' },
  { value: 13, text: 'Offre ciné' },
  { value: 14, text: 'Offre étudiant' },
  { value: 15, text: 'Anniversaire' },
  { value: 16, text: 'Salle privatisé' },
  { value: 17, text: 'Salle climatisé' },
  { value: 18, text: 'Wifi gratuite' },
  { value: 19, text: 'Terrasse' },
  { value: 20, text: 'Brunch' },
  { value: 21, text: 'Petit déjeuné' },
  { value: 22, text: 'Cuisson sur table' },
  { value: 23, text: 'Cuisson au feu de bois' },
  { value: 24, text: 'Chèque vacance' },
  { value: 25, text: 'Buffet à volonté' },
  { value: 26, text: 'Happy hour' },
  { value: 27, text: 'Coktail maison' }
]
