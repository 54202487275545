const TYPES = [
  'Aucun',
  'Asiatique',
  'Japonais',
  'Thailandais',
  'Chinois',
  'Italien',
  'FastFood',
  'Salon de thé',
  'Bubble tea',
  'Américain',
  'Français',
  'Sandwich',
  'Burger',
  'Vietnamien',
  'Chicken',
  'Glace',
  'Coréen',
  'Indien',
  'Volonté',
  'Halal',
  'Mexicain',
  'Pizza',
  'Sushis',
  'Brunch',
  'Healthy',
  'Salade',
  'Marocain',
  'Fruits de mer',
  'Pâtes',
  'Maison de Bao',
  'Cusine chinoise',
  'Cuisine du monde',
  'Buffet',
  'Buffet à volonté',
  'Pakistanais',
  'Orientale',
  'Végan',
  'Hallal',
  'Spécialité Hawaïenne',
  'Ramen'
]

export function getBusinessTypes(arr) {
  if (arr === undefined) return ''
  let str = ''

  arr.forEach(id => {
    if (id !== -1 && str === '')
      str = str.concat('', TYPES[id])
    else if (id !== -1 && str !== '')
      str = str.concat(' • ', TYPES[id])
  });
  return str
}