import React from "react"
import serviceTypes from "../utils/serviceTypes"

export default function Services({ data, size }) {
  if (data.restaurant.informations?.serviceType === undefined) return <></>
  return (
    <div className="services grid grid-cols-2 lg:grid-cols-4">

      {data.restaurant.informations?.serviceType[0] > 0 && (
        <div className="h-28 w-28 flex flex-col items-center justify-center">
          <div className="h-10 w-10"><img src={`/assets/${data.restaurant.informations?.serviceType[0]}.png`} /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-10">{serviceTypes[data.restaurant.informations?.serviceType[0]]?.text}</div>
        </div>
      )}

      {data.restaurant.informations?.serviceType[1] > 0 && (
        <div className="h-28 w-28 flex flex-col items-center justify-center">
          <div className="h-10 w-10"><img src={`/assets/${data.restaurant.informations?.serviceType[1]}.png`} /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-10">{serviceTypes[data.restaurant.informations?.serviceType[1]]?.text}</div>
        </div>
      )}

      {data.restaurant.informations?.serviceType[2] > 0 && (
        <div className="h-28 w-28 flex flex-col items-center justify-center">
          <div className="h-10 w-10"><img src={`/assets/${data.restaurant.informations?.serviceType[2]}.png`} /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-10">{serviceTypes[data.restaurant.informations?.serviceType[2]]?.text}</div>
        </div>
      )}

      {data.restaurant.informations?.serviceType[3] > 0 && (
        <div className="h-28 w-28 flex flex-col items-center justify-center">
          <div className="h-10 w-10"><img src={`/assets/${data.restaurant.informations?.serviceType[3]}.png`} /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-10">{serviceTypes[data.restaurant.informations?.serviceType[3]]?.text}</div>
        </div>
      )}

      {data.restaurant.informations?.serviceType[4] > 0 && (
        <div className="h-28 w-28 flex flex-col items-center justify-center">
          <div className="h-10 w-10"><img src={`/assets/${data.restaurant.informations?.serviceType[4]}.png`} /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-10">{serviceTypes[data.restaurant.informations?.serviceType[4]]?.text}</div>
        </div>
      )}

      {data.restaurant.informations?.serviceType[5] > 0 && (
        <div className="h-28 w-28 flex flex-col items-center justify-center">
          <div className="h-10 w-10"><img src={`/assets/${data.restaurant.informations?.serviceType[5]}.png`} /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-10">{serviceTypes[data.restaurant.informations?.serviceType[5]]?.text}</div>
        </div>
      )}

      {data.restaurant.informations?.serviceType[6] > 0 && (
        <div className="h-28 w-28 flex flex-col items-center justify-center">
          <div className="h-10 w-10"><img src={`/assets/${data.restaurant.informations?.serviceType[6]}.png`} /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-10">{serviceTypes[data.restaurant.informations?.serviceType[6]]?.text}</div>
        </div>
      )}

      {data.restaurant.informations?.serviceType[7] > 0 && (
        <div className="h-28 w-28 flex flex-col items-center justify-center">
          <div className="h-10 w-10"><img src={`/assets/${data.restaurant.informations?.serviceType[7]}.png`} /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-10">{serviceTypes[data.restaurant.informations?.serviceType[7]]?.text}</div>
        </div>
      )}

    </div>
  )
}
